import { RealtimeClient } from '@openai/realtime-api-beta';
import type { ItemType } from '@openai/realtime-api-beta/dist/lib/client';
import { marked } from 'marked';
import { v4 as uuidv4 } from 'uuid';
import { proxy } from 'valtio';
import { subscribeKey } from 'valtio/utils';

import {
  ClientAspectRatio,
  type DtoBlock,
  type DtoImageGenRequest,
  EnumsExternalMediaProvider,
} from '@lp-lib/api-service-client/public';
import { type Logger } from '@lp-lib/logger-base';

import type {
  AITutorToolCalledProps,
  LearningAnalytics,
} from '../../../../analytics/learning';
import config from '../../../../config';
import { getFeatureQueryParam } from '../../../../hooks/useFeatureQueryParam';
import { apiService } from '../../../../services/api-service';
import {
  audioSessionExec,
  audioSessionResetQuality,
} from '../../../../services/audio/audio-session';
import { sleep } from '../../../../utils/common';
import {
  type IMicVolumeMeter,
  MicVolumeMeterProcessor,
} from '../../../../utils/MicVolumeMeter';
import { type SimplifedAgentInfo } from '../../../../utils/user-agent';
import { WavRecorder, WavStreamPlayer } from '../../../../vendor/wavtools';
import { hasAudioSignal } from '../../blocks/Roleplay/RoleplayTalkingIndicator';
import { type BlockDependencies } from '../../types';
import { type SFXControl } from '../SFXControl';

export type TutorAvailabilityState = 'available' | 'unavailable';

export type TutorHandState = 'raised' | 'lowered';

export type TutorConnectionState = 'disconnected' | 'connecting' | 'connected';

type ContentDescription = { type: 'markdown'; markdown: string };

interface TutorState {
  availabilityState: TutorAvailabilityState;
  handState: TutorHandState;
  connectionState: TutorConnectionState;
  isOpen: boolean;

  // Add feature toggle state
  isFeatureEnabled: boolean;
  error?: Nullable<string>;
  // content
  isLoading: boolean;
  loadingText?: string;
  contentDesc?: Nullable<ContentDescription>;
  markdownHTML?: string;
  contentExpanded: boolean;
  // tutor
  tutorState?: Nullable<'speaking' | 'waiting' | 'listening' | 'thinking'>;
  tutorQuestion?: Nullable<{ question: string; finishCriteria?: string }>;
  // user
  speechState?: Nullable<'started' | 'stopped'>;
  communicationPreference?: Nullable<'voice' | 'text'>;
  communicationDisabled?: boolean;
  // recording
  isRecordCooldown: boolean;
  // rating
  showRating: boolean;
  rating: Nullable<'thumbsUp' | 'thumbsDown'>;
  // imgs
  imageLookup: Record<string, string>;
}

export class TutorControl {
  private _state = proxy<TutorState>({
    availabilityState: 'unavailable',
    handState: 'lowered',
    connectionState: 'disconnected',
    isOpen: false,
    isFeatureEnabled: false,
    isLoading: false,
    contentExpanded: false,
    isRecordCooldown: false,
    showRating: false,
    rating: null,
    imageLookup: {},
  });

  private realtimeControl: Nullable<RealtimeClient>;
  private currentBlock: Nullable<DtoBlock>;
  private _wavRecorder: Nullable<WavRecorder>;
  private _wavRecorderProcessor: Nullable<MicVolumeMeterProcessor>;
  private wavStreamPlayer: WavStreamPlayer;
  private url: URL;
  private tutorSpeakingDetectionInterval: Nullable<NodeJS.Timeout>;
  private openedAt: Nullable<number>;
  // Track used image URLs to avoid duplicates
  private usedImageUrls = new Set<string>();

  // Getter for wavRecorderProcessor
  get wavRecorderProcessor(): Nullable<MicVolumeMeterProcessor> {
    return this._wavRecorderProcessor;
  }

  constructor(
    private logger: Logger,
    private sfxControl: SFXControl,
    getAudioContext: () => Nullable<AudioContext>,
    private getPack: BlockDependencies['getPack'],
    private getUser: BlockDependencies['getUser'],
    private agentInfo: SimplifedAgentInfo,
    private analytics?: Nullable<LearningAnalytics>
  ) {
    const url = new URL(config.api.baseUrl);
    if (url.protocol === 'http:') {
      url.protocol = 'ws:';
    } else if (url.protocol === 'https:') {
      url.protocol = 'wss:';
    } else {
      throw new Error(`Unsupported protocol: ${url.protocol}`);
    }
    url.pathname += '/openai/realtime';
    this.url = url;
    const context = getAudioContext();
    this.wavStreamPlayer = new WavStreamPlayer({ context });
  }

  get state() {
    return this._state;
  }

  /**
   * Checks if the tutor is enabled both by feature flag and programmatic control
   */
  get isEnabled(): boolean {
    return getFeatureQueryParam('ai-tutor') && this._state.isFeatureEnabled;
  }

  handleButtonClick(): void {
    if (this._state.handState === 'raised') {
      this.setHandState('lowered');
      this._state.isOpen = false;
    } else {
      this.setHandState('raised');
      // we should open as soon as the hand is raised.
      this._state.isOpen = true;
    }
  }

  /**
   * Set whether the tutor feature is enabled
   */
  setFeatureEnabled(enabled: boolean) {
    this._state.isFeatureEnabled = enabled;

    // If feature is disabled, ensure overlay is closed and hand is lowered
    if (!enabled) {
      this._state.handState = 'lowered';
    }
  }

  /**
   * Sets the tutor availability state
   */
  setAvailabilityState(state: TutorAvailabilityState) {
    this._state.availabilityState = state;

    // If hand is raised and tutor becomes available, automatically open the overlay
    if (state === 'available' && this._state.handState === 'raised') {
      this.connect();
    }
  }

  setCurrentBlock(block: Nullable<DtoBlock>) {
    this.currentBlock = block;
  }

  /**
   * Sets the hand state (raised or lowered)
   */
  setHandState(state: TutorHandState) {
    this._state.handState = state;

    // If hand is raised and tutor is available, open the overlay immediately
    if (state === 'raised' && this._state.availabilityState === 'available') {
      this.connect();
    }
  }

  toggleContentExpanded() {
    this._state.contentExpanded = !this._state.contentExpanded;
  }

  /**
   * Resets the state for reuse
   */
  reset() {
    this.wavStreamPlayer.interrupt();
    this._wavRecorder?.quit();
    this._wavRecorder = undefined;
    this._wavRecorderProcessor?.stop();
    this._wavRecorderProcessor = undefined;
    this.resetContent();
    this._state.isOpen = false;
    this._state.connectionState = 'disconnected';
    this._state.availabilityState = 'unavailable';
    this._state.handState = 'lowered';
    this._state.tutorQuestion = undefined;
    this._state.showRating = false;
    this._state.rating = undefined;
    // we always want the user to select, at each block change...
    this._state.communicationPreference = undefined;
    this.currentBlock = undefined;
    this.realtimeControl?.disconnect();
    this.realtimeControl = undefined;
    this.openedAt = undefined;
  }

  resetContent() {
    this._state.isLoading = false;
    this._state.loadingText = undefined;
    this._state.error = undefined;
    this._state.contentDesc = undefined;
    this._state.markdownHTML = undefined;
    this._state.contentExpanded = false;
    this._state.communicationDisabled = undefined;
    // Clear the used image URLs when content is reset
    this.usedImageUrls.clear();
    this._state.imageLookup = {};
    this._state.tutorQuestion = undefined;
  }

  /**
   * Connects to the realtime API
   */
  async connect(tutorQuestion?: { question: string; finishCriteria?: string }) {
    if (!tutorQuestion && this._state.availabilityState !== 'available') return;

    if (this.currentBlock) {
      const pack = this.getPack();
      this.analytics?.trackAITutorOpened({
        blockId: this.currentBlock.id,
        blockType: this.currentBlock.type,
        packId: pack.id,
      });
    }

    // ensure we are open if we are connecting...
    this._state.isOpen = true;
    this._state.connectionState = 'connecting';
    this._state.showRating = false;
    this._state.rating = null;
    this.sfxControl.play('instructionHoverReadyButton');
    this.openedAt = Date.now();

    if (tutorQuestion?.question) {
      this._state.tutorQuestion = tutorQuestion;
    }

    try {
      await this.initRealtimeClient();
      this._state.connectionState = 'connected';
    } catch (error) {
      this.logger.error('Failed to connect to realtime API', error);
      this._state.error =
        'Failed to connect to AI Tutor. Please try again later.';
      this._state.connectionState = 'disconnected';
      this._state.handState = 'lowered';
    }
  }

  async waitForTutorClosed() {
    if (!this._state.isOpen) return;

    const { promise, resolve } = Promise.withResolvers<void>();
    const unsub = subscribeKey(this._state, 'isOpen', (v) => {
      if (!v) resolve();
    });

    return promise.finally(() => unsub());
  }

  async setCommunicationPreference(pref: 'voice' | 'text') {
    this._state.communicationPreference = pref;
  }

  /**
   * Disconnects from the realtime API
   */
  async disconnect() {
    this.sfxControl.play('instructionHoverReadyButton');

    if (this.currentBlock) {
      const pack = this.getPack();
      this.analytics?.trackAITutorClosed({
        blockId: this.currentBlock.id,
        blockType: this.currentBlock.type,
        packId: pack.id,
        sessionDurationMs: this.openedAt ? Date.now() - this.openedAt : 0,
        communicationType: this._state.communicationPreference,
      });
    }

    try {
      this.realtimeControl?.disconnect();
      this.realtimeControl = null;
      await this.wavStreamPlayer?.interrupt();

      if (this._wavRecorder) {
        await this._wavRecorder.quit();
        this._wavRecorder = null;
        this._wavRecorderProcessor?.stop();
        this._wavRecorderProcessor = null;
        audioSessionResetQuality();
      }

      if (this.tutorSpeakingDetectionInterval) {
        clearInterval(this.tutorSpeakingDetectionInterval);
      }
    } finally {
      const connectionState = this._state.connectionState;
      this.resetContent();
      this._state.isOpen = false;
      this._state.handState = 'lowered';
      this._state.connectionState = 'disconnected';
      this.openedAt = undefined;
      if (connectionState === 'connected') {
        this._state.showRating = true;
      }
    }
  }

  async submitRating(rating: 'thumbsUp' | 'thumbsDown') {
    this._state.rating = rating;
    this.analytics?.trackAITutorRatingSubmitted({
      packId: this.getPack().id,
      blockId: this.currentBlock?.id ?? '',
      blockType: this.currentBlock?.type ?? '',
      rating,
    });
  }

  async dismissRating() {
    this.analytics?.trackAITutorRatingDismissed({
      packId: this.getPack().id,
      blockId: this.currentBlock?.id ?? '',
      blockType: this.currentBlock?.type ?? '',
    });
  }

  closeRating() {
    this._state.showRating = false;
    this._state.rating = null;
  }

  async sendTextInput(text: string) {
    if (!this.realtimeControl) return;
    await this.wavStreamPlayer.interrupt();
    this.realtimeControl.sendUserMessageContent([
      {
        type: 'input_text',
        text,
      },
    ]);
    this.tutorStartSpeaking();
  }

  async show(desc: ContentDescription) {
    if (this._state.connectionState !== 'connected') return;

    this.resetContent();

    this._state.contentDesc = desc;
    this._state.contentExpanded = true;
    this._state.error = undefined;

    if (desc.type === 'markdown') {
      await this.handleMarkdown(desc);
    }
  }

  private async handleMarkdown(
    content: Extract<ContentDescription, { type: 'markdown' }>
  ) {
    this._state.isLoading = true;
    this._state.loadingText = 'Thinking...';

    try {
      const html = await marked(content.markdown);

      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      const imgs = doc.querySelectorAll('img');

      for (const img of imgs) {
        const src = img.getAttribute('src');
        const prompt = img.getAttribute('title') || img.getAttribute('alt');

        const id = uuidv4();
        img.dataset.id = id;
        img.src =
          'data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2016%209%22%3E%3Crect%20width%3D%22100%25%22%20height%3D%22100%25%22%20fill%3D%22%23383838%22%2F%3E%3C%2Fsvg%3E';
        img.className = 'aspect-video w-full h-auto rounded animate-pulse';

        if ((src === 'genai' || src === 'search') && prompt) {
          if (src === 'genai') {
            this.processAIGeneratedImage(id, prompt);
          } else if (src === 'search') {
            this.processImageSearch(id, prompt);
          }
        }
      }

      this._state.markdownHTML = doc.body.innerHTML;
    } catch (error) {
      this.logger.error('Failed to parse markdown', { error });
      this._state.error = 'Error rendering markdown content';
    } finally {
      this._state.isLoading = false;
      this._state.loadingText = undefined;
    }
  }

  private async processAIGeneratedImage(
    id: string,
    prompt: string
  ): Promise<void> {
    try {
      // Generate image using the prompt
      const request: DtoImageGenRequest = {
        provider: 'Together',
        model: 'FLUX.1-schnell-Free',
        prompt,
        aspectRatio: ClientAspectRatio.ASPECTRATIO_WIDE,
        num: 1,
      };
      const response = await apiService.aiGeneral.generateImages(request);

      const imageData = response.data.images?.[0]?.b64;
      if (imageData) {
        const dataUrl = `data:image/png;base64,${imageData}`;
        this.usedImageUrls.add(dataUrl);
        this._state.imageLookup[id] = dataUrl;
      }
    } catch (error) {
      this.logger.error('Failed to generate image', { error, prompt });
    }
  }

  private async processImageSearch(id: string, query: string): Promise<void> {
    try {
      const sessionId = uuidv4();
      const searchResponse = await apiService.training.selectBestMatchMedia({
        sessionId,
        keyword: query,
        limit: 6,
        promptTemplateMappingKey: 'tutor/select-best-match-media',
        provider: EnumsExternalMediaProvider.ExternalMediaProviderAzureBing,
        filterStaleUrls: true,
      });

      if (
        searchResponse.data.results &&
        searchResponse.data.results.length > 0
      ) {
        const availableResults = searchResponse.data.results
          .filter((r) => r.mediaItem && r.mediaItem.url)
          .map((r) => r.mediaItem?.url || '')
          .filter((url) => url !== '');

        if (availableResults.length === 0) return;

        let selectedUrl = availableResults.find(
          (url) => !this.usedImageUrls.has(url)
        );

        if (!selectedUrl && availableResults.length > 0) {
          selectedUrl = availableResults[0];
        }

        if (selectedUrl) {
          // Mark this URL as used
          this.usedImageUrls.add(selectedUrl);
          this._state.imageLookup[id] = selectedUrl;
        }
      }
    } catch (error) {
      this.logger.error('Failed to search for images', { error, query });
    }
  }

  private async initRealtimeClient() {
    this.realtimeControl = new RealtimeClient({ url: this.url.toString() });
    this.configureRealtimeClient(this.realtimeControl);

    await this.wavStreamPlayer.connect();
    await this.realtimeControl.connect();
    this.tutorStartSpeaking();
  }

  private async connectMic() {
    // connect to microphone
    const wavRecorder = new WavRecorder({
      // OpenAI requires 24000, but Firefox doesn't support resampling.
      // We use the system sample rate here instead, and let the wavRecorder
      // resample it to 24000.
      sampleRate: this.agentInfo.browser.isFirefox ? undefined : 24000,
    });
    this._wavRecorder = wavRecorder;

    try {
      await audioSessionExec(() => wavRecorder.begin());
      if (wavRecorder.analyser) {
        this._wavRecorderProcessor = new MicVolumeMeterProcessor(
          new WavRecorderAnalyserAdapter(wavRecorder.analyser)
        );
        this._wavRecorderProcessor.start(20);
      }
    } catch (error) {
      audioSessionResetQuality();
      this.logger.error('connecting error', error);
      throw error;
    }
  }

  private configureRealtimeClient(rtc: RealtimeClient) {
    rtc.updateSession({
      input_audio_transcription: { model: 'whisper-1' },
      voice: 'alloy',
      turn_detection: null,
    });

    rtc.on('error', (event: unknown) => {
      this.logger.error('Error', event);
    });

    rtc.on('conversation.interrupted', async () => {
      const trackSampleOffset = this.wavStreamPlayer.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        rtc.cancelResponse(trackId, offset);
      }
    });

    rtc.on(
      'conversation.updated',
      async ({ item, delta }: EventConversationUpdatedPayload) => {
        if (delta?.audio) {
          this.wavStreamPlayer.add16BitPCM(delta.audio, item.id);
        }
        if (item.status === 'completed' && item.formatted.audio?.length) {
          const wavFile = await WavRecorder.decode(
            item.formatted.audio,
            24000,
            24000
          );
          item.formatted.file = wavFile;
        }
      }
    );

    if (this._state.tutorQuestion) {
      this.configureRealtimeClientForTutorQuestion(rtc);
    } else {
      this.configureRealtimeClientForRaisedHand(rtc);
    }
  }

  private configureRealtimeClientForRaisedHand(rtc: RealtimeClient) {
    rtc.updateSession({ instructions: this.buildInstructionsForRaisedHand() });
    rtc.addTool(
      {
        name: 'showWhiteboardWithMarkdown',
        description: 'Shows the whiteboard with the given markdown content',
        parameters: {
          type: 'object',
          properties: {
            markdown: {
              type: 'string',
            },
          },
          required: ['markdown'],
        },
      },
      async ({ markdown }: { markdown: string }) => {
        const sanitizedInputParams = {
          markdownLength: markdown.length,
          markdownPreview:
            markdown.substring(0, 50) + (markdown.length > 50 ? '...' : ''),
        };

        try {
          return await this.trackToolUsage(
            'showWhiteboardWithMarkdown',
            sanitizedInputParams,
            async () => {
              await this.show({ type: 'markdown', markdown });
              return { ok: true };
            }
          );
        } catch (e) {
          return { ok: false };
        }
      }
    );

    rtc.addTool(
      {
        name: 'queryKnowledgeBase',
        description:
          'Queries the knowledge base for information related to this specific course or assignment.',
        parameters: {
          type: 'object',
          properties: {
            query: {
              type: 'string',
            },
          },
          required: ['query'],
        },
      },
      async ({ query }: { query: string }) => {
        try {
          return await this.trackToolUsage(
            'queryKnowledgeBase',
            { query },
            async () => {
              const resp = await this.queryKnowledgeBase(query);
              return { ok: true, resp };
            },
            (result) => {
              const resp = result.resp;
              const resultCount = resp.docs?.length || 0;
              const scores = resp.docs?.map((doc) => doc.score) || [];
              const averageScore =
                scores.length > 0
                  ? scores.reduce((sum, score) => sum + score, 0) /
                    scores.length
                  : 0;

              return {
                resultCount,
                averageScore,
              };
            }
          );
        } catch (e) {
          return { ok: false };
        }
      }
    );
  }

  private configureRealtimeClientForTutorQuestion(rtc: RealtimeClient) {
    rtc.updateSession({
      instructions: this.buildInstructionsForTutorQuestion(),
    });
    rtc.addTool(
      {
        name: 'endSession',
        description:
          'Ends the session when the learner has provided a satisfactory answer',
        parameters: {},
      },
      async () => {
        return await this.trackToolUsage('endSession', {}, async () => {
          this._state.communicationDisabled = true;

          const calledAt = new Date();
          let lastSpeakingAt = new Date();
          // Wait for the assistant to stop speaking, max
          while (true) {
            if (
              this.wavStreamPlayer &&
              hasAudioSignal(
                this.wavStreamPlayer.getFrequencies('voice').values
              )
            ) {
              lastSpeakingAt = new Date();
            }

            const nowTime = new Date().getTime();
            // Wait for 30 seconds, max
            if (nowTime - calledAt.getTime() > 30 * 1000) {
              break;
            }

            // If assistant is stopped for more than 1 second, it's time to end the conversation
            if (nowTime - lastSpeakingAt.getTime() > 1000) {
              break;
            }

            await sleep(300);
          }

          await this.disconnect();
          return { ok: true };
        });
      }
    );
  }

  /**
   * Helper function to track tool usage analytics across all tutor tools
   * @param toolName Name of the tool being used
   * @param inputParams Parameters passed to the tool (sanitized if needed)
   * @param operation The actual operation to perform
   * @param getAdditionalMetrics Optional callback to extract additional metrics from the result
   * @returns The result of the operation
   */
  private async trackToolUsage<T extends Record<string, unknown>, R>(
    toolName: string,
    inputParams: T,
    operation: () => Promise<R>,
    getAdditionalMetrics?: (result: R) => Record<string, unknown>
  ): Promise<R> {
    const currentState = this._state.tutorState;
    try {
      this._state.tutorState = 'thinking';
      const result = await operation();

      // Track successful tool usage
      if (this.analytics && this.currentBlock) {
        // Get the packId
        const pack = this.getPack();

        // Create the base analytics props
        const analyticsProps: AITutorToolCalledProps = {
          blockId: this.currentBlock.id,
          blockType: this.currentBlock.type,
          packId: pack.id,
          toolName,
          inputParams,
          responseStatus: 'success',
        };

        // Add any additional metrics if provided
        if (getAdditionalMetrics && result) {
          Object.assign(analyticsProps, getAdditionalMetrics(result));
        }

        this.analytics.trackAITutorToolCalled(analyticsProps);
      }

      return result;
    } catch (error) {
      // Track failed tool usage
      if (this.analytics && this.currentBlock) {
        const pack = this.getPack();

        this.analytics.trackAITutorToolCalled({
          blockId: this.currentBlock.id,
          blockType: this.currentBlock.type,
          packId: pack.id,
          toolName,
          inputParams,
          responseStatus: 'error',
        });
      }
      throw error;
    } finally {
      this._state.tutorState = currentState;
    }
  }

  async startRecording() {
    if (!this._wavRecorder) {
      try {
        await this.connectMic();
      } catch (error) {
        // if the user does not give permission, proceed with text input.
        await this.setCommunicationPreference('text');
        return;
      }
    } else {
      this._wavRecorderProcessor?.start();
    }

    await this.setCommunicationPreference('voice');

    this._state.tutorState = 'listening';
    this._state.isRecordCooldown = true;
    setTimeout(() => {
      this._state.isRecordCooldown = false;
    }, 300);
    const trackSampleOffset = await this.wavStreamPlayer.interrupt();
    if (trackSampleOffset?.trackId) {
      const { trackId, offset } = trackSampleOffset;
      await this.realtimeControl?.cancelResponse(trackId, offset);
    }
    await this._wavRecorder?.record((data) =>
      this.realtimeControl?.appendInputAudio(data.mono)
    );
  }

  async stopRecording() {
    this._state.isRecordCooldown = true;
    setTimeout(() => {
      this._state.isRecordCooldown = false;
    }, 300);
    await this._wavRecorder?.pause();
    this.tutorStartSpeaking();
  }

  private async queryKnowledgeBase(query: string) {
    const pack = this.getPack();
    const resp = await apiService.gamePack.searchKnowledgeBase(pack.id, {
      query,
      limit: 5,
      scoreThreshold: 0.6,
    });
    return resp.data;
  }

  private tutorStartSpeaking() {
    this.realtimeControl?.createResponse();

    this._state.tutorState = 'speaking';

    if (this.tutorSpeakingDetectionInterval) {
      clearInterval(this.tutorSpeakingDetectionInterval);
    }
    let assistantSpeakAt = new Date().getTime();
    this.tutorSpeakingDetectionInterval = setInterval(() => {
      if (this._state.tutorState !== 'speaking') {
        if (this.tutorSpeakingDetectionInterval) {
          clearInterval(this.tutorSpeakingDetectionInterval);
        }
        return;
      }

      if (hasAudioSignal(this.wavStreamPlayer.getFrequencies('voice').values)) {
        assistantSpeakAt = new Date().getTime();
      }

      // If the assistant has not spoken for 2 seconds, it's time for user to talk
      if (new Date().getTime() - assistantSpeakAt > 2000) {
        this._state.tutorState = 'waiting';
      }
    }, 500);
  }

  private buildInstructionsForRaisedHand(): string {
    // Build the base sections
    const learnerDetails = `<learnerDetails>
${this.buildLearnerFacts()}
</learnerDetails>`;

    const courseDetails = `<courseDetails>
${this.buildCourseFacts()}
</courseDetails>`;

    const blockDetails = `<blockDetails>
${this.buildBlockFacts()}
</blockDetails>`;

    // Define the tools section
    const toolsSection = `<tools>
You have access to the following tools to enhance your tutoring capabilities. If these tools ever fail, DO NOT tell the
user there were technical problems; just respond to the user's request/question and continue the conversation as normal.

<tool name="showWhiteboardWithMarkdown">
Displays a "whiteboard" with formatted markdown content for detailed explanations.

IMPORTANT:
- DO NOT use this tool for your first response to the learner
- For all subsequent responses, you MUST use this tool for every substantive response
- Include at least one image in each whiteboard response unless doing so would distract from learning
- After showing the whiteboard, provide a BRIEF verbal explanation - no more than 1-2 sentences
- Be concise in both your whiteboard content and verbal explanations

When writing markdown content:
1. Use proper markdown structure with headers, lists, and emphasis
2. Break up text with appropriate spacing and organization
3. Include at least one relevant image in every response (preferably using search)
4. Explain complex concepts with multiple images when needed
5. Keep all text brief and focused - avoid unnecessary words

You can include images using these special image tags:
- <img src="search" title="search query for finding a real image" /> (PREFERRED for most cases)
- <img src="genai" title="prompt for generating an image" /> (ONLY for special cases)

Best practices for markdown formatting:
- Avoid a single paragraph of text
- Use headers (## and ###) to organize complex content
- Use bullet points or numbered lists for steps or multiple items
- Use bold and italic for emphasis
- Use code blocks for code examples or specific syntax, or for math equations
- Break content into clear sections with headers
- Include a summary or key points section for longer explanations
</tool>

<tool name="queryKnowledgeBase">
Queries the knowledge base for information related to this specific course or assignment.

When to use:
- WHENEVER a student asks about specific course content, assignments, or concepts from the course material
- When you are unsure or only partially confident about course-specific details or concepts
- When the student asks about a term, concept, or fact that isn't common knowledge
- When there could be course-specific definitions of otherwise common terms
- Before attempting to answer any question about course material, to ensure accuracy
- When a student seems confused about material you've explained, to check if there's additional context

Important: You MUST query the knowledge base at least once before providing detailed explanations on course-specific topics. This ensures you have access to the most accurate, course-specific information rather than relying solely on your general knowledge.

Example queries:
- The exact topic the student is asking about
- Key concepts mentioned in the student's question
- Related concepts that might help explain the topic
- Specific terms or definitions from the course material

The knowledge base contains the specific materials from this course. Use it to enhance your answers with accurate, course-specific information.
</tool>
</tools>`;

    // Define the markdown templates section
    const markdownTemplatesSection = `<markdownTemplates>
Use these template formats for your whiteboard responses. Vary your approach based on the content you're presenting. KEEP ALL CONTENT CONCISE.

1. HEADLINE AND BULLETS
\`\`\`markdown
# Key Takeaways

- Clear communication is essential
- Simplicity beats complexity
- Design with the user in mind
- Test and iterate frequently
\`\`\`

2. HEADLINE AND PARAGRAPH
\`\`\`markdown
# Why User Feedback Matters

Gathering user feedback early helps spot issues before they grow. It validates assumptions and ensures you're solving real problems, not imaginary ones.
\`\`\`

3. HEADLINE AND BULLETS WITH INLINE IMAGE
\`\`\`markdown
# Product Highlights

<img src="genai" title="modern wearable fitness tracker with display showing heart rate" />

- Tracks heart rate and sleep
- Supports GPS and music
- Water-resistant up to 50m
- Battery life: 10 days
\`\`\`

4. HEADLINE AND PARAGRAPH WITH INLINE IMAGE
\`\`\`markdown
# Behind the Scenes

<img src="search" title="team of designers and engineers collaborating at whiteboard" />

Our product team collaborates with users, designers, and engineers to ensure rapid iteration based on real-world insights.
\`\`\`

5. LARGE IMAGE WITH TEXT BELOW
\`\`\`markdown
<img src="genai" title="sunrise over mountains with inspiring landscape" />

*Each new day is a chance to reset and refocus with purpose.*
\`\`\`
</markdownTemplates>`;

    // Define image guidance section
    const imageGuidanceSection = `IMAGES IN RESPONSES:
- Aim to include at least one image in EVERY whiteboard response, unless doing so would actively detract from your explanation
- Include two or more images when explaining complex topics or multi-step processes
- Treat images as ESSENTIAL teaching tools, not optional enhancements
- NEVER apologize for including images or mention how they were generated
- Focus on teaching with the image, not discussing the image itself

If you need to include images in your response, use the following special syntax directly in your markdown:
- For searched images (PREFERRED): <img src="search" title="your search query here" />
- For AI-generated images (SPECIAL CASES ONLY): <img src="genai" title="your image prompt here" />

IMPORTANT: Always use image search (<img src="search">) as your DEFAULT choice when including images, unless the specific content requires an AI-generated image.

When to use image search (<img src="search">):
- DEFAULT OPTION for most teaching situations
- For real people, places, or historical events
- For diagrams, charts, processes, and technical illustrations
- For actual photographs of specific objects, animals, or places
- For established scientific imagery and educational visuals
- When showing examples from the real world
- When factual accuracy and authenticity are important
- For any concept that has existing visual representations

When to use AI-generated images (<img src="genai">):
- ONLY for special cases where search is unlikely to find appropriate images
- For abstract concepts that don't have typical visual representations
- For simple metaphorical or imaginative illustrations
- For stylized or artistic representations when a specific style is needed
- When a truly generic or non-specific example is required
- AVOID using for any technical, factual, or process-based content
- NEVER use for diagrams, charts, technical illustrations, or precise visual information

Best practices for all images:
- Explain what the image shows and its significance
- Point out key features or relationships in the image
- Use the image as a visual aid to support your explanation
- Connect the image to the concept being taught
- Ask questions about what the learner observes in the image
- When using multiple images, use specific and varied prompts to ensure diversity

These special image tags will be automatically processed and replaced with actual images when displayed to the user.`;

    // Build the prompt specifically for raised hand scenario
    return `
You are an expert tutor. Your primary goal is to help a user learn material they may be struggling to understand, to answer any questions they might have about the material. I will give you information about the learner, the course, and the current content (block) they are working on.

BEGIN THE CONVERSATION with a direct greeting like "Hi [learner name], what can I help you with?" DO NOT mention specific course content or introduce the topic in your greeting. Avoid saying things like "I see you're learning about [topic]" or "What question do you have about [content]?". Just provide a simple, direct greeting and ask how you can help.

Remember: you are acting as a tutor. For content where there is a correct answer, you should use tutoring methods to help the user arrive at the answer, don't just give it to them right away. 

${learnerDetails}

${courseDetails}

${blockDetails}

<importantInstructions>
For your FIRST response only:
- DO NOT use the whiteboard
- Keep it EXTREMELY brief - just 1-2 short sentences
- Use a greeting like: "Hi [name], what can I help you with?" or "Hi there, how can I help you?"
- DO NOT mention course content or specific topics in your greeting

For ALL SUBSEQUENT responses:
- ALWAYS display your responses using the whiteboard feature by calling the showWhiteboardWithMarkdown tool with your content
- You MUST call this tool for EVERY substantive response after your initial greeting
- Your whiteboard content should be concise and focused - avoid unnecessary text or verbose explanations
- After showing the whiteboard, provide a BRIEF verbal summary - no more than 1-2 sentences
- Keep your explanations short, clear, and to the point throughout

You should always structure your whiteboard responses as well-formatted markdown with appropriate headers, lists, and emphasis.

${imageGuidanceSection}

Remember: Do NOT use the whiteboard for your first response, but it is MANDATORY for all subsequent responses.
</importantInstructions>

${markdownTemplatesSection}

${toolsSection}
`;
  }

  private buildInstructionsForTutorQuestion(): string {
    if (!this._state.tutorQuestion) {
      return this.buildInstructionsForRaisedHand();
    }

    // Build the base sections
    const learnerDetails = `<learnerDetails>
${this.buildLearnerFacts()}
</learnerDetails>`;

    const courseDetails = `<courseDetails>
${this.buildCourseFacts()}
</courseDetails>`;

    const blockDetails = `<blockDetails>
${this.buildBlockFacts()}
</blockDetails>`;

    // Define the tools section - simplified for tutor question mode
    const toolsSection = `<tools>
<tool name="endSession">
Ends the current tutoring session and closes the tutor interface.

When to use:
${
  this._state.tutorQuestion.finishCriteria
    ? `- ONLY when the learner has provided an answer that meets the specific finish criteria: "${this._state.tutorQuestion.finishCriteria}"
- Continue engaging with the learner until their answer satisfies these criteria
- If their answer is close but not quite there, guide them toward a more complete response`
    : `- When you've received a reasonable answer to the question
- When the learner has demonstrated understanding of the key concept
- When the conversation has reached a natural conclusion
- Focus on keeping the interaction concise - end the session once you have a satisfactory answer`
}

Important: 
- Before ending the session, briefly acknowledge the learner's answer positively
- Only call this tool once you're confident the interaction is complete
- The session will immediately close when this tool is called
</tool>
</tools>`;

    return `
You are an expert tutor. Your primary goal is to ask a specific question to the learner.

Your role is to:
1. Ask the specified question (already displayed to the learner)
2. Help the learner understand what you're asking if they're confused
3. Provide hints or guidance if they're struggling, but don't give away the answer
4. Evaluate their response based on ${
      this._state.tutorQuestion.finishCriteria
        ? 'the specific finish criteria (specified below)'
        : 'whether it reasonably answers the question'
    }
5. Once they've provided a satisfactory answer, acknowledge it positively
6. After receiving a satisfactory answer, you MUST use the endSession tool to end the interaction

${learnerDetails}

${courseDetails}

${blockDetails}

<tutorQuestion>
The user is currently seeing the following question as if you asked them, and you are waiting for their response.
Question: ${this._state.tutorQuestion.question}
${
  this._state.tutorQuestion.finishCriteria
    ? `Finish Criteria: ${this._state.tutorQuestion.finishCriteria}`
    : ''
}
</tutorQuestion>

${toolsSection}

BEGIN THE CONVERSATION by asking the following question which is already displayed to the learner:
"${this._state.tutorQuestion.question}"
`;
  }

  private buildCourseFacts(): string {
    const pack = this.getPack();
    const facts = [];
    if (pack.name) {
      facts.push(`Name: ${pack.name}`);
    }
    if (pack.description) {
      facts.push(`Description: ${pack.description}`);
    }
    return facts.join('\n');
  }

  private buildLearnerFacts(): string {
    const user = this.getUser();
    if (!user) return 'No user';
    return `Name: ${user.username}`;
  }

  private buildBlockFacts(): string {
    if (!this.currentBlock) return 'No block';
    return JSON.stringify(this.currentBlock);
  }
}

// note(falcon): cloned, from VoiceChatProvider
type EventConversationUpdatedPayload = {
  item: {
    id: ItemType['id'];
    status: 'completed' | 'in_progress' | 'incomplete';
    formatted: ItemType['formatted'];
  };
  delta: {
    audio?: Int16Array | ArrayBuffer;
  };
};

class WavRecorderAnalyserAdapter implements IMicVolumeMeter {
  private readonly freqData: Uint8Array;
  private readonly timeData: Uint8Array;

  constructor(private analyser: AnalyserNode) {
    this.freqData = new Uint8Array(this.analyser.frequencyBinCount);
    this.timeData = new Uint8Array(this.analyser.frequencyBinCount);
  }

  getByteFrequencyData(): Readonly<Uint8Array> {
    this.analyser.getByteFrequencyData(this.freqData);
    return this.freqData;
  }

  getByteTimeDomainData(): Readonly<Uint8Array> {
    this.analyser.getByteTimeDomainData(this.timeData);
    return this.timeData;
  }

  close(): void {
    // The wavRecorder will handle this itself
  }
}
